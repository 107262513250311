import { pageClick } from '@wix/bi-logger-new-help-center/v2';
import { useCallback } from 'react';
import { BI } from '../../constants';
import { useBI } from '../../hooks/useBI';

export const useSiteSelectorHeroBI = (locale: string) => {
    const { sendBIEvent } = useBI();

    const onScrollDownEvent = useCallback(() => {
        void sendBIEvent(
            pageClick({
                source_name: BI.SOURCE_NAMES.HOME,
                kb_lang: locale,
                clicked_item_type: BI.CLICKED_ITEM_TYPES.HOME_PAGE_EXPAND,
                clicked_text: BI.EXPLORE_MORE,
            })
        );
    }, [locale]);

    return { onScrollDownEvent };
};
