import { Box, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';

export enum SiteSelectorStateType {
    ERROR = 'error',
    EMPTY = 'empty',
}

export type SiteSelectorStateProps = {
    type: SiteSelectorStateType;
    query?: string;
};

export const SiteSelectorState = ({ type, query }: SiteSelectorStateProps) => {
    const { t } = useTranslation();

    const getTitle = () => {
        switch (type) {
            case SiteSelectorStateType.EMPTY:
                return t('site-selector.empty-state.title', { query });
            default:
                return t('site-selector.error-state.title');
        }
    };

    return (
        <Box
            direction="vertical"
            gap={1}
            data-hook={DATA_HOOKS.SITE_SELECTOR_STATE}
            align="center"
            verticalAlign="middle"
            padding={4}
            className={css.SiteSelectorState}
        >
            <Text size='medium' weight='normal' dataHook={DATA_HOOKS.SITE_SELECTOR_STATE_TITLE} className={css.SiteSelectorStateTitle}>
                {getTitle()}
            </Text>
            <Text size='small' weight='thin' dataHook={DATA_HOOKS.SITE_SELECTOR_STATE_TEXT} className={css.SiteSelectorStateText}>
                {t(`site-selector.${type}-state.text`)}
            </Text>
        </Box >
    );
};
