import { Badge, Box, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import Image from 'next/image';
import ConfirmIcon from 'wix-ui-icons-common/Confirm';
import { TOOLTIP_ENTER_DELAY } from '../../../../constants';
import { DATA_HOOKS } from '../../../../dataHooks';
import { SiteMetaData } from '../../../../types';
import css from './index.module.scss';

export type SiteSelectorListItemProps = {
    site: SiteMetaData;
    currentSiteId?: string | null;
    withScroll?: boolean;
    onSiteItemClick: (site: SiteMetaData) => void;
};

export const siteThumbnail = (id: string) => `https://manage.wix.com/site-thumbnail/${id}?preset=site-list`;

export const SiteSelectorListItem = ({ site, withScroll, currentSiteId, onSiteItemClick }: SiteSelectorListItemProps) => {
    const { t } = useTranslation();

    return (
        <div
            key={site.metaSiteId}
            onClick={() => onSiteItemClick(site)}
            data-hook={DATA_HOOKS.SITE_SELECTOR_LIST_ITEM}
            className={classNames(css.SiteSelectorItem, { [css.withScroll]: withScroll })}
        >
            <Box
                verticalAlign="middle"
                align="space-between"
                className={classNames(css.SiteSelectorItemContent, { [css.selected]: currentSiteId === site.metaSiteId })}
            >
                <Box verticalAlign="middle" overflow="hidden">
                    <Image src={siteThumbnail(site.metaSiteId)} className={css.siteImage} alt={site.name} width={65} height={35} />
                    <Box direction="vertical" overflow="hidden">
                        <Text
                            className={css.siteName}
                            size='medium'
                            weight='thin'
                            ellipsis
                            tooltipProps={{ enterDelay: TOOLTIP_ENTER_DELAY }}
                            dataHook={DATA_HOOKS.SITE_SELECTOR_LIST_ITEM_NAME}
                        >
                            {site.siteProperties?.siteDisplayName}
                        </Text>
                    </Box>
                </Box>

                <Box verticalAlign="middle" gap={2}>
                    {site.premiumAssigned && (
                        <Badge
                            size='tiny'
                            skin="premium"
                            className={css.premiumBadge}
                            dataHook={DATA_HOOKS.SITE_SELECTOR_LIST_ITEM_PREMIUM_BADGE}
                        >
                            {t('site-selector-modal.premium-ribbon.label')}
                        </Badge>
                    )}

                    <Box height={24} width={24}>
                        {currentSiteId === site.metaSiteId && (
                            <Box dataHook={DATA_HOOKS.SITE_SELECTOR_LIST_ITEM_CONFIRM_ICON} className={css.confirmIcon}>
                                <ConfirmIcon />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </div>
    );
};
