import type { NextPage } from 'next';
import { Box } from '@wix/design-system';
import { Article, Category, CategoryArticles } from '@wix/answers-api';
import { withServerSideProps } from '../utils/with-server-side-props';
import { getHelpCenterData, getPageSeo } from '../services/answers';
import { EXPERIMENTS, KA_SEARCH_EXPANDED_LOCALES, LOCALES } from '../constants';
import { KbBlog, PageType, PAGES } from '../types';
import { DATA_HOOKS } from '../dataHooks';
import { useRouter } from 'next/router';
import { TrendingArticles } from '../components/TrendingArticles';
import { TopicsSection } from '../components/TopicsSection';
import { ExploreMoreResources } from '../components/ExploreMoreResources';
import { MarketplaceEntry } from '../components/MarketplaceEntry';
import { RoadmapBanner } from '../components/RoadmapBanner';
import css from './HomePage.module.scss';
import { getUserProfile } from '../services/user';
import { useContext, useMemo, useRef } from 'react';
import { StudioCommunityForumEntry } from '../components/StudioCommunityForumEntry';
import { Context } from '../context';
import { fetchBlogs } from '../services/wix-blogs';
import { Blogs } from '../components/Blogs';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { SiteSelectorHero } from '../components/SiteSelectorHero';
import { KnowledgeAgentSearchHero } from '../components/KnowledgeAgentSearchHero';
import { Hero } from '../components/Hero';
import classNames from 'classnames';

export type HomePageProps = {
  categoriesTopArticles: CategoryArticles[];
  trendingArticles: Article[];
  categoriesTree: Category[];
  blogs: KbBlog[];
};

const HomePage: NextPage<HomePageProps> = ({
  trendingArticles,
  categoriesTree = [],
  blogs,
}) => {
  const { locale } = useRouter();
  const { isWixStudioUser } = useContext(Context);
  const { experiments } = useExperiments({ readOnly: true });
  const contentRef = useRef(null);

  const isExpandedKnowledgeAgentEnabled =
    experiments.enabled(EXPERIMENTS.SPECS.KA_SEARCH_EXPANDED_LOCALES) &&
    KA_SEARCH_EXPANDED_LOCALES.includes(locale as string);

  const isSiteSelector =
    experiments.enabled(EXPERIMENTS.SPECS.SITE_PICKER) && locale === LOCALES.EN;
  const useKnowledgeAgentSearch =
    locale === LOCALES.EN || isExpandedKnowledgeAgentEnabled;

  const scrollIntoViewIfNeeded = () => {
    if (contentRef.current) {
      const focusedItem = contentRef.current as HTMLElement;
      if (focusedItem) {
        focusedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  };

  const HeroComponent = useMemo(() => {
    if (isSiteSelector) {
      return <SiteSelectorHero onScrollDown={scrollIntoViewIfNeeded} />;
    } else if (useKnowledgeAgentSearch) {
      return <KnowledgeAgentSearchHero onScrollDown={scrollIntoViewIfNeeded} />;
    }
    return <Hero />;
  }, [isSiteSelector, useKnowledgeAgentSearch]);

  return (
    <Box
      direction="vertical"
      className={`${css.wrapper} ${css.newHomepage}`}
      dataHook={DATA_HOOKS.HOME_PAGE}
    >
      {HeroComponent}
      <div
        ref={contentRef}
        className={classNames({
          [css.contentWrapper]: useKnowledgeAgentSearch,
          [css.siteSelector]: isSiteSelector,
        })}
      >
        {!isSiteSelector && <TrendingArticles articles={trendingArticles} />}
        <TopicsSection topics={categoriesTree} />
        <RoadmapBanner />
        {blogs.length > 0 && <Blogs blogs={blogs} />}
        <ExploreMoreResources
          isShowBlogResource={!blogs.length}
          isNonEnglishLocale={locale !== LOCALES.EN}
        />
        {isWixStudioUser ? <StudioCommunityForumEntry /> : <MarketplaceEntry />}
      </div>
    </Box>
  );
};

export const getServerSideProps = withServerSideProps(
  async ({ req, res, locale, isLoggedInUser }) => {
    const { categories: categoriesTree, articles: globalTrendingArticles } =
      await getHelpCenterData(req.aspects, locale, res.locals.redisClient);

    const pageSeo = await getPageSeo(
      req.aspects,
      locale,
      PageType.Homepage,
      res.locals.redisClient
    );

    const blogs = await fetchBlogs(
      res.locals.appContext,
      req,
      locale,
      res.locals.redisClient
    );

    let userProfile = null;
    const trendingArticles = globalTrendingArticles;

    if (isLoggedInUser) {
      const { user } = await getUserProfile(req.aspects, res.locals.appContext);
      userProfile = user;
    }

    return {
      props: {
        pageType: PAGES.HOMEPAGE,
        categoriesTree,
        pageSeo,
        userProfile,
        trendingArticles,
        blogs,
        pageData: {},
      },
    };
  }
);

export default HomePage;
