type SearchBadge = {
    key: string;
};

export const SITE_SELECTOR_HERO_BADGES: SearchBadge[] = [
    {
        key: 'homepage.site-selector.suggestion-connect-domain',
    },
    {
        key: 'homepage.site-selector.suggestion-choose-premium',
    },
    {
        key: 'homepage.site-selector.suggestion-create-dynamic',
    },
    {
        key: 'homepage.site-selector.suggestion-setup-analytics',
    },
    {
        key: 'homepage.site-selector.suggestion-connect-email',
    }
];
