import { helpCenterModals } from '@wix/bi-logger-customer-care-data/v2';
import { helpCenterModalsParams } from '@wix/bi-logger-customer-care-data/v2/types';
import { useRouter } from 'next/router';
import { useCallback, useContext } from 'react';
import { BI } from '../../constants';
import { Context } from '../../context';
import { useBI } from '../../hooks/useBI';

export const useSiteSelectorBI = (sessionId: string) => {
    const { sessionBI } = useContext(Context);
    const { locale } = useRouter();
    const { sendBIEvent } = useBI();

    const sharedProps: Partial<helpCenterModalsParams> = {
        modal_name: BI.MODAL_NAME.SITE_PICKER,
        modal_session_id: sessionId,
        source_name: BI.SOURCE_NAMES.HOME,
        self_service_session_id: sessionBI.selfServiceSessionId || undefined,
        kb_lang: locale,
        screen_name: BI.SCREEN_NAME.MAIN,
    };

    const getClickedButtonName = (clickedItemId?: string) => {
        if (clickedItemId) {
            return BI.CLICKED_ITEM_TYPES.SPECIFIC_SITE;
        } else {
            return BI.CLICKED_ITEM_TYPES.ALL_SITES;
        }
    };

    return {
        onClickSiteOrAllSites: useCallback((previousSelectedSiteId?: string, clickedItemId?: string) => {
            void sendBIEvent(
                helpCenterModals({
                    ...sharedProps,
                    action_type: BI.ACTION_TYPE.MODAL_CLICK,
                    clicked_button_name: getClickedButtonName(clickedItemId),
                    clicked_item_id: clickedItemId,
                    params: previousSelectedSiteId
                })
            );
        }, [sendBIEvent]),
    };
};
