import { Box, Divider, Popover, Search, TextButton } from '@wix/design-system';
import { useHttpClient } from '@wix/fe-essentials-standalone';
import { useTranslation } from '@wix/wix-i18n-config';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { Context } from '../../context';
import { DATA_HOOKS } from '../../dataHooks';
import { useDebounce } from '../../hooks/useDebounce';
import { GetUserSitesResponse } from '../../services/user/sites';
import { SiteMetaData } from '../../types';
import { SiteSelectorList } from './SiteSelectorList';
import { SiteSelectorState, SiteSelectorStateType } from './SiteSelectorStates';
import { useSiteSelectorBI } from './bi';

export type SiteSelectorProps = {
    shown: boolean;
    children: JSX.Element;
    onToggle: () => void;
    onClickOption: (site?: SiteMetaData) => void;
};

export const SiteSelector = ({ shown, onToggle, onClickOption, children }: SiteSelectorProps) => {
    const { siteSelectorData, setUserSelectedSite } = useContext(Context);
    const { sites: initialSites, itemsCount, selectedSite } = siteSelectorData;
    const [sites, setSites] = useState<SiteMetaData[]>(initialSites);
    const [loading, setLoading] = useState(false);
    const [query, setQuery] = useState<string>();
    const debouncedSearchTerm = useDebounce(query, 300);
    const [errorOrEmptyState, setErrorOrEmptyState] = useState<SiteSelectorStateType | null>(null);
    const { t } = useTranslation();
    const httpClient = useHttpClient();
    const bi = useSiteSelectorBI(v4());

    const fetchSites = useCallback(async (options?: { clear?: boolean, term?: string, page?: number }) => {
        const { data } = await httpClient.get<GetUserSitesResponse>('/api/user/sites', {
            params: { term: options?.term, page: options?.page }
        });

        if (options?.clear && data.sites.length === 0) {
            setErrorOrEmptyState(SiteSelectorStateType.EMPTY);
        }

        const updatedSites = options?.clear ? data.sites : [...sites, ...data.sites];
        setSites(updatedSites);
    }, [sites]);

    const onSearch = async (term: string) => {
        setQuery(term);
    };

    useEffect(() => {
        void (async () => {
            if (debouncedSearchTerm !== undefined) {
                try {
                    setLoading(true);
                    await fetchSites({ clear: true, term: debouncedSearchTerm });
                } catch (e) {
                    setErrorOrEmptyState(SiteSelectorStateType.ERROR);
                } finally {
                    setLoading(false);
                }
            }
        })();
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (shown) {
            setQuery(undefined);
            setErrorOrEmptyState(null);
        }
    }, [shown]);

    const textButtonLabel = useMemo(() => {
        if (query) {
            return t('site-selector-modal.clear-search');
        }
        if (errorOrEmptyState === SiteSelectorStateType.ERROR) {
            return t('site-selector-modal.refresh');
        }
        if (selectedSite) {
            return t('site-selector-modal.clear-selection');
        }
        return t('site-selector-modal.select-all-sites');
    }, [query, errorOrEmptyState, selectedSite]);


    const onSiteItemClick = (site: SiteMetaData) => {
        const previousSelectedSiteId = selectedSite?.metaSiteId || undefined;
        setUserSelectedSite(site);
        onClickOption(site);
        bi.onClickSiteOrAllSites(previousSelectedSiteId, site.metaSiteId);
    };

    const onSelectAllSites = () => {
        const previousSelectedSiteId = selectedSite?.metaSiteId || undefined;
        setUserSelectedSite(null);
        onClickOption();
        bi.onClickSiteOrAllSites(previousSelectedSiteId);
    };

    const onClearSearch = async () => {
        await onSearch('');
    };

    if (!siteSelectorData || !siteSelectorData.sites) {
        return null;
    }

    return (
        <Popover
            placement="bottom"
            dataHook={DATA_HOOKS.SITE_SELECTOR_POPOVER}
            onClickOutside={onToggle}
            showArrow
            moveBy={{ y: 10 }}
            shown={shown}
        >
            <Popover.Element>
                {children}
            </Popover.Element>
            <Popover.Content>
                <Box
                    direction="vertical"
                    verticalAlign="space-between"
                    height='100%'
                    width='450px'
                    boxSizing='border-box'
                >
                    <Box verticalAlign="middle" boxSizing='border-box' padding='2 4' height='60px'>
                        <Search size='small' placeholder={t('site-selector-modal.search')}
                            onClear={() => onSearch('')}
                            dataHook={DATA_HOOKS.SITE_SELECTOR_SEARCH}
                            onChange={(e) => onSearch(e.target.value)}
                            status={loading ? 'loading' : undefined} />
                    </Box>
                    {errorOrEmptyState ?
                        <SiteSelectorState type={errorOrEmptyState} query={query} /> :
                        <SiteSelectorList
                            sites={sites}
                            currentSiteId={selectedSite?.metaSiteId}
                            onSiteItemClick={onSiteItemClick}
                            totalSites={itemsCount || 0}
                            fetchMoreSites={fetchSites}
                        />
                    }
                    <Divider />
                    <Box verticalAlign="middle" align="center" height='67px'>
                        <TextButton weight='thin' size='medium' dataHook={DATA_HOOKS.SITE_SELECTOR_TEXT_BUTTON}
                            onClick={errorOrEmptyState ? onClearSearch : onSelectAllSites} ellipsis>
                            {textButtonLabel}
                        </TextButton>
                    </Box>
                </Box>
            </Popover.Content>
        </Popover>
    );
};
