import { Box, Divider, Heading, Loader, Table, TableColumn } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import { useState } from 'react';
import { SITE_PICKER_PAGE_SIZE } from '../../../constants';
import { DATA_HOOKS } from '../../../dataHooks';
import { SiteMetaData } from '../../../types';
import css from './index.module.scss';
import { SiteSelectorListItem } from './SiteSelectorListItem';

export type SiteSelectorListProps = {
    sites: SiteMetaData[];
    totalSites: number;
    currentSiteId?: string | null;
    fetchMoreSites: () => void;
    onSiteItemClick: (site: SiteMetaData) => void;
};

export const SiteSelectorList = ({ sites, currentSiteId, totalSites, fetchMoreSites, onSiteItemClick }: SiteSelectorListProps) => {
    const { t } = useTranslation();
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const shouldShowScroll = sites.length > 4;
    const hasMore = totalSites > sites.length;

    const columns: TableColumn<SiteMetaData>[] = [
        {
            title: null,
            style: { padding: 0, boxShadow: 'none' },
            render: (site: SiteMetaData, rowIdx: number) => (
                <>
                    <SiteSelectorListItem site={site} currentSiteId={currentSiteId} onSiteItemClick={onSiteItemClick} withScroll={shouldShowScroll} />
                    {rowIdx < sites.length - 1 &&
                        (<Box className={css.divider}>
                            <Divider />
                        </Box>
                        )
                    }
                </>
            ),
        },
    ];

    return (<Box
        direction="vertical"
        className={classNames(css.SiteSelectorList, { [css.scroll]: shouldShowScroll })}
        dataHook={DATA_HOOKS.SITE_SELECTOR_LIST}
        ref={setRef}
    >
        {ref ? <Table
            infiniteScroll
            hasMore={hasMore}
            loadMore={fetchMoreSites}
            itemsPerPage={SITE_PICKER_PAGE_SIZE}
            data={sites}
            columns={columns}
            scrollElement={ref}
            loader={(
                <Box className={css.loadingMoreSites} direction="vertical" align="center">
                    <Loader size='small' />
                    <Heading size='extraTiny' className={css.loadingMoreSitesText}>
                        {t('site-selector-modal.loading-sites')}
                    </Heading>
                </Box>
            )}
        >
            <Table.Content titleBarVisible={false} />
        </Table> : null}
    </Box>
    );
};
