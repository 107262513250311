import { Box, Button, Heading, IconButton, Image, Input, Tag, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FunctionComponent, KeyboardEvent, MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { } from 'wix-ui-icons-common';
import { Context } from '../../context';
import { DATA_HOOKS } from '../../dataHooks';
import { useGoToSearchResultsPage } from '../../hooks/useGoToSearchResultsPage';
import { PAGES } from '../../types';
import { InnerLink } from '../InnerLink';
import { RenderHTML } from '../RenderHTML';
import { SiteSelector } from '../SiteSelector';
import { SITE_SELECTOR_HERO_BADGES } from './badges';
import { useSiteSelectorHeroBI } from './bi';
import css from './index.module.scss';
import { MAX_SEARCH_INPUT_LENGTH } from '../KnowledgeAgentSearchHero';
import { useIsGenAnswer } from '../../hooks/useIsGenAnswer';
import { SparklesFilled as SparklesFilledIcon, ChevronDownSmall as ChevronDownIcon, Search as SearchIcon, ArrowRight as ArrowRightIcon } from '@wix/wix-ui-icons-common';

export type SiteSelectorHeroProps = {
  onScrollDown: () => void;
};

type BadgeClickEventType = KeyboardEvent<Element> | MouseEvent<HTMLAnchorElement, globalThis.MouseEvent> | MouseEvent<Element, globalThis.MouseEvent>;

export const SiteSelectorHero: FunctionComponent<SiteSelectorHeroProps> = ({ onScrollDown }) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const { user, siteSelectorData } = useContext(Context);
  const { selectedSite } = siteSelectorData;
  const { locale } = useRouter();
  const { onScrollDownEvent } = useSiteSelectorHeroBI(locale as string);
  const { goToSearchResultsPage } = useGoToSearchResultsPage();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [query, setQuery] = useState<string>('');

  const onScrollDownPage = useCallback(() => {
    void onScrollDownEvent();
    onScrollDown();
  }, [onScrollDown, onScrollDownEvent]);

  const closePopover = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  const isGenAnswerEnabled = useIsGenAnswer();

  const goToSearchResultsPageWrapper = async (overrideQuery?: string, e?: BadgeClickEventType) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (query || overrideQuery) {
      await goToSearchResultsPage({
        term: query,
        previousPageType: PAGES.HOMEPAGE,
        overrideTerm: overrideQuery,
      });
    }
  };


  const SiteDisplayName = useMemo(() => {
    if (selectedSite) {
      return selectedSite.siteProperties.siteDisplayName;
    } else {
      return t('site-selector.all-sites');
    }
  }, [selectedSite]);

  return (
    <Box className={css.wrapper} direction="horizontal" verticalAlign="middle" width='100%'>
      <Box className={css.innerWrapper} direction="vertical" verticalAlign="middle" align="center" margin='83px' width='100%'>
        <Box className={css.leftAndRightSide} direction="horizontal" verticalAlign="middle" align="space-between" width='100%' flex={1}>
          <Box className={css.leftSide} direction="vertical">
            <Heading size='large' className={css.title} dataHook={DATA_HOOKS.SITE_SELECTOR_HERO_TITLE}>
              {user?.firstName ? <RenderHTML html={t('site-selector.personalized-title', { firstName: user?.firstName })} />
                : t('site-selector.title')}
            </Heading>
            <Heading size='small' className={css.subtitle}>
              {t('site-selector.subtitle')}
              <SiteSelector shown={isPopoverOpen} onToggle={closePopover} onClickOption={closePopover}>
                <TextButton weight='bold' className={classNames(css.SiteSelectorPopoverOpenButton, { [css.SiteSelectorPopoverOpenButtonOpen]: isPopoverOpen })}
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)} ellipsis
                  suffixIcon={<ChevronDownIcon className={css.chevronDownIcon} />}>
                  {SiteDisplayName}
                </TextButton>
              </SiteSelector>
            </Heading>
            <Box direction="horizontal" className={css.searchBar}>
              <Input
                size="medium"
                autoFocus={true}
                maxLength={MAX_SEARCH_INPUT_LENGTH}
                dataHook={DATA_HOOKS.HOME_SEARCH_INPUT}
                className={css.searchInput}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={t('site-selector.search-placeholder')}
                prefix={
                  <Input.IconAffix>
                    {isGenAnswerEnabled ? (
                      <SparklesFilledIcon />
                    ) : (
                      <SearchIcon />
                    )}
                  </Input.IconAffix>
                }
                suffix={
                  isGenAnswerEnabled && context.isLoggedInUser ? (
                    <Input.IconAffix>
                      <Button
                        className={css.searchIconAI}
                        size="medium"
                        skin="ai"
                        dataHook={DATA_HOOKS.HERO_SEARCH_BUTTON}
                        onClick={() => goToSearchResultsPageWrapper()}
                      >
                        {t('site-selector.search.button.gen-answer')}
                      </Button>
                    </Input.IconAffix>
                  ) : (
                    <Input.IconAffix>
                      <IconButton
                        className={css.searchArrowIconAI}
                        size="large"
                        skin="ai"
                        dataHook={DATA_HOOKS.HERO_SEARCH_BUTTON}
                        onClick={() => goToSearchResultsPageWrapper()}
                      >
                        <ArrowRightIcon />
                      </IconButton>
                    </Input.IconAffix>
                  )
                }
                onEnterPressed={() => goToSearchResultsPageWrapper()}
                value={query}
              />
            </Box>
            <Box className={css.badges} flexWrap='wrap' gap={1}>
              {SITE_SELECTOR_HERO_BADGES.map((badge) => (
                <InnerLink key={badge.key} href={`/search?term=${t(badge.key)}`} className={css.badgeLink} onClick={(e) => goToSearchResultsPageWrapper(t(badge.key), e)}>
                  <Tag id={badge.key} size='medium' className={css.badge} removable={false} theme='lightOutlined' dataHook={DATA_HOOKS.SITE_SELECTOR_HERO_BADGE}>
                    <Text size='tiny' weight='thin' className={css.badgeText}>
                      {t(badge.key)}
                    </Text>
                  </Tag>
                </InnerLink>
              ))}
            </Box>
          </Box>
          <Box className={css.rightSide}>
            <Image src='https://s3-alpha-sig.figma.com/img/8562/f22e/a6958dd0b3104a863e30c659e0239f44?Expires=1739750400&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=dDYZ-m1Ee2--fwqlbGeArrfkxLj30qhScdtGsQnaLWOp64MI7lYaiHiQ9c-Zh1nVWy23mE6G45~Keba~onDCgtJBGp1Ax0H29UOgziuMnzBL30wUEjeA5BJmkiCsgKqizSI4asVrMSMYSYcx6Qv-uov1jsrMZ7wgRRTi-L4lYUjuKa2nxO0uXUyUU~snF038Qt1wnQTZ1KHUnTFr7rM919Y4IFakvTPpRS-kMpOEONYLkV95Jg4Zw3tOmS96P9el4J44CQEke~xGSei6DDiSSupnQhPTOmRnk4bTcgvh0Zzy9hyd5tDESo-I6jSLWadRTzhNK4DTTmAaKfD6OuR-XQ__'
              alt='site-selector' width={400} height={302} borderRadius={30} />
          </Box>
        </Box>
        <TextButton
          dataHook={DATA_HOOKS.SCROLL_DOWN_ARROW}
          className={css.exploreMoreContainer}
          aria-label={t('homepage.knowledge-agent-search.explore-more')}
          onClick={onScrollDownPage}>
          <Box className={css.exploreMore} direction="vertical" verticalAlign="middle" align="center">
            <Text skin="primary" className={css.exploreMoreText} ellipsis maxWidth={100}>
              {t('homepage.knowledge-agent-search.explore-more')}
            </Text>
            <Box className={css.exploreMoreArrow}>
              <ChevronDownIcon className={css.exploreMoreIcon} />
            </Box>
          </Box>
        </TextButton>
      </Box>
    </Box>
  );
};
